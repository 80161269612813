<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t("Filters") }}
      </h5>
      <b-button @click="clearFilters" variant="secondary" size="sm">{{ $t("Limpar Filtros") }}</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-2">
          <label>{{ $t("Data inicial") }}</label>
          <flat-pickr v-model="startDate" class="form-control" name="date" placeholder="Data inicial" />
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <label>{{ $t("Data final") }}</label>
          <flat-pickr v-model="endDate" class="form-control" name="date" placeholder="Data final" />
        </b-col>
        <b-col cols="12" md="6" class="mb-2" v-if="productShow">
          <label>{{ $t("Produto") }}</label>
          <v-select v-model="selectedProduct" :options="productOptions" placeholder="Selecione um produto" />
        </b-col>
        <b-col cols="12" md="6" class="mb-2" v-if="methodShow">
          <label>{{ $t("Método de Pagamento") }}</label>
          <v-select v-model="selectedMethod" :options="methodOptions" placeholder="Selecione um método de pagamento" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr
  },
  props: {
    productShow: Boolean,
    methodShow: Boolean
  },
  data() {
    // Funções para obter o início e o fim do mês atual
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
    return {
      // Definindo as datas de início e fim do mês atual
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      selectedProduct: { label: 'Licença', value: 'license' },
      productOptions: [
        { label: 'Licença', value: 'license' },
        { label: 'Aporte', value: 'contribution' }
      ],
      selectedMethod: { label: 'Carteira web3 (USDT)', value: 'usdt' },
      methodOptions: [
        { label: 'Carteira web3 (USDT)', value: 'usdt' },
        { label: 'Ativação manual (PIX)', value: 'pix' },
        { label: 'Patrocínio', value: 'sponsorship' },
        { label: 'Reinvestimento com saldo', value: 'reinvestment' },
        { label: 'Emprestado pela empresa', value: 'borrowed' },
      ],
    };
  },
  mounted() {
    // Emitir valores padrões assim que o componente for montado
    this.$emit('updateStartDate', this.startDate);
    this.$emit('updateEndDate', this.endDate);
    this.$emit('updateSelectedProduct', this.selectedProduct);
    this.$emit('updateSelectedMethod', this.selectedMethod);
  },
  methods: {
    clearFilters() {
      const today = new Date();
      this.startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Início do mês atual
      this.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Fim do mês atual
      this.selectedProduct = { label: 'Licença', value: 'license' };
      this.selectedMethod = { label: 'Carteira web3 (USDT)', value: 'usdt' };
      
      // Emitir os valores padrão novamente ao limpar os filtros
      this.$emit('updateStartDate', this.startDate);
      this.$emit('updateEndDate', this.endDate);
      this.$emit('updateSelectedProduct', this.selectedProduct);
      this.$emit('updateSelectedMethod', this.selectedMethod);
    },
  },
  watch: {
    startDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.$emit('updateStartDate', newDate);
      }
    },
    endDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.$emit('updateEndDate', newDate);
      }
    },
    selectedProduct(newProduct, oldProduct) {
      if (newProduct !== oldProduct) {
        this.$emit('updateSelectedProduct', newProduct);
      }
    },
    selectedMethod(newMethod, oldMethod) {
      if (newMethod !== oldMethod) {
        this.$emit('updateSelectedMethod', newMethod);
      }
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
