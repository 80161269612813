<!-- WithdrawStatsCard.vue -->
<template>
  <b-card
    :title="title"
    :sub-title="subTitle"
    header-bg-variant="dark"
    header-text-variant="white"
  >
    <b-card-text>
      <b-row>
        <b-col>Total em USDT:</b-col>
        <b-col>{{ totalValueUSD }}</b-col>
      </b-row>
      <b-row>
        <b-col>Total com desconto:</b-col>
        <b-col>{{ totalDiscountedUSDValue }}</b-col>
      </b-row>
      <b-row>
        <b-col>Total em Crypto:</b-col>
        <b-col>{{ totalValueCrypto }} {{ title }}</b-col>
      </b-row>
      <b-row>
        <b-col>Contagem:</b-col>
        <b-col>{{ count }}</b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    totalValueUSD: Number,
    totalDiscountedUSDValue: Number,
    totalValueCrypto: Number,
    count: Number,
  },
};
</script>
